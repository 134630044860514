












































































































































































import Vue from "vue";
import axios from "axios";
import { Rule } from "@/types/rule";
import { Importance } from "@/types/importance";
import { ImportancewithAlle } from "@/types/importance";
import store from "@/store";

export default Vue.extend({
  name: "RulesComponent",
  data: () => ({
    rules: [] as Rule[],
    documentTypes: ["Alle"],
    selectedDocumentType: "Alle",
    selectedGewichtType: 0,
    Importance: Importance,
    ImportancewithAlle: ImportancewithAlle,
    dialog: false,
    editedIndex: -1,
    editedRule: {
      name: "",
      importance: 0,
      explanation: "",
      docstring: "",
    },
    defaultRule: {
      name: "",
      id: "",
      object_type: "",
      importance: 0,
      explanation: "",
      docstring: "",
    },
    headers: [
      { text: "Actief", value: "enabled", width: "90px" },
      { text: "id", value: "id" },
      {
        text: "Naam",
        align: "start",
        value: "name",
      },
      { text: "Gewicht", value: "importance" },
      { text: "Omschrijving", value: "explanation" },
      { text: "Uitleg uitgebreid", value: "docstring" },
      {
        text: store.state.user.isAuthenticated ? "Aanpassen" : "",
        value: "actions",
        sortable: false,
        align: "center",
      },
    ],
  }),
  watch: {
    dialog(val) {
      val || this.close();
    },
  },
  computed: {
    filteredRules: function (): Rule[] {
      if (store.state.user.isAuthenticated) {
        return this.rules.filter(
          (rule: Rule) =>
            (rule.object_type == this.selectedDocumentType ||
              this.selectedDocumentType === "Alle") &&
            (rule.importance == this.selectedGewichtType ||
              this.selectedGewichtType === 0)
        );
      } else {
        return this.rules.filter(
          (rule: Rule) => rule.importance == 2 || rule.importance == 3
        );
      }
    },
    sortedGewicht() {
      let gewichten = Object.values(ImportancewithAlle)
        .filter((value) => typeof value === "string")
        .map((key: any) => ({
          key: key,
          value: ImportancewithAlle[key],
        }));
      return gewichten;
    },
  },
  methods: {
    getRules() {
      axios
        .get(`${this.$store.state.APIurl}/rule`, {
          headers: { Authorization: `Bearer ${this.$store.state.user.token}` },
        })
        .then((response) => {
          this.$data.rules = response.data;
          const presentTypes: string[] = [
            ...new Set(this.$data.rules.map((rule: Rule) => rule.object_type)),
          ] as string[];
          this.$data.documentTypes = ["Alle"].concat(presentTypes.sort());
        })
        .catch(() => {
          this.$data.rules = [];
        });
    },
    editRule(rule: Rule) {
      this.editedIndex = this.rules.indexOf(rule);
      this.editedRule = Object.assign({}, rule);
      this.dialog = true;
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedRule = Object.assign({}, this.defaultRule);
        this.editedIndex = -1;
      });
    },
    saveRule(rule: Rule) {
      if (this.editedIndex > -1) {
        Object.assign(this.rules[this.editedIndex], this.editedRule);
        axios
          .put(`${this.$store.state.APIurl}/rule/${rule.object_type}/${rule.id}`, rule, {
            headers: {
              Authorization: `Bearer ${this.$store.state.user.token}`,
            },
          })
          .then((response) => {
            console.log(response);
          })
          .catch((error) => {
            console.log(error);
          });
      }
      this.close();
    },
  },
  mounted() {
    this.getRules();
  },
});
