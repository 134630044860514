export enum ImportancewithAlle {
  Alle = 0,
  Info = 1,
  Waarschuwing = 2,
  Fout = 3,
}

export enum Importance {
  Info = 1,
  Waarschuwing = 2,
  Fout = 3,
}